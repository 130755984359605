import React, { Fragment } from 'react';
import { navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Link from '../components/Link';

const Blog = ({
  data: { site, allMdx },
  pageContext: { pagination },
}) => {
  const { page, nextPagePath, previousPagePath } = pagination;

  const posts = page.map((id) =>
    allMdx.edges.find((edge) => edge.node.id === id),
  );

  return (
    <Layout site={site}>
      {posts.map(({ node: post }) => (
        <Post
          key={post.id}
          onClick={() => navigate(post.frontmatter.slug)}
        >
          {post.frontmatter.banner && (
            <CoverImage
              sizes={post.frontmatter.banner.childImageSharp.sizes}
            />
          )}

          <h2>
            <Link to={post.frontmatter.slug}>
              {post.frontmatter.title}
            </Link>
          </h2>

          <small>{post.frontmatter.date}</small>

          <p>{post.excerpt}</p>
        </Post>
      ))}
    </Layout>
  );
};

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      ...site
    }
    allMdx {
      edges {
        node {
          excerpt(pruneLength: 300)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            banner {
              childImageSharp {
                sizes(maxWidth: 720) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            slug
            keywords
          }
        }
      }
    }
  }
`;

const Post = styled.div`
  cursor: pointer;
  transition: transform 0.2s;

  a {
    text-decoration: none !important;
    color: #000 !important;
  }

  margin: 40px 0;

  padding-bottom: 30px;
  border-bottom: 1px solid #555;
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    transform: translate(-5px, -5px);
  }
`;

export const CoverImage = styled(Img)`
  width: 100%;
  max-height: 200px;
`;
